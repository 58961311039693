import http from '../../axios';

export const fetchResultEventsList = async race =>
  http.get('/result/events', {
    params: {
      race,
    },
  });

export const fetchResultCategoriesList = async (raceID, event) =>
  http.get('/result/categories', {
    params: {
      raceID,
      event,
    },
  });

export const fetchResultsList = async (raceID, event, gender, category, page) =>
  http.get('/result/list', {
    params: {
      raceID,
      event,
      gender,
      category,
      page,
    },
  });

export const fetchResultsSearch = async (raceID, event, search) =>
  http.get('/result/search', {
    params: {
      raceID,
      event,
      search,
    },
  });

export const fetchResultsDashboardNovo = async (orgID, startDate, endDate) =>
  http.get('/result/dashboard/novoHealth', {
    params: {
      orgID,
      startDate,
      endDate,
    },
  });

export const fetchResultsSearchNovo = async (orgID, search) =>
  http.get('/result/search/novoHealth', {
    params: {
      orgID,
      search,
    },
  });

export const fetchResultDetails = async (raceID, event, bibNo, name) =>
  http.get('/result/details', {
    params: {
      raceID,
      event,
      bibNo,
      name,
    },
  });
