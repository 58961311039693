import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Gauge, CalendarBlank, MagnifyingGlass } from 'phosphor-react';

const race = {
  id: '350ee1c3-9e43-4273-900a-93ee51c63fec',
  name: 'Novohealth 5K',
};

const options = [
  {
    id: 'c99c1e80-29cc-4444-87f0-36d40760c7c3',
    name: 'Dashboard',
    icon: <Gauge size={50} />,
    link: '/novoHealth/dashboard',
  },
  {
    id: '73c6b357-a3db-43ef-aa34-62e916953220',
    name: 'Weekly Results',
    icon: <CalendarBlank size={50} />,
    link: `/results?org=${race.id}`,
  },
  {
    id: 'bef32118-fae0-4d81-a85b-f38f24b0035c',
    name: 'Search History',
    icon: <MagnifyingGlass size={50} />,
    link: '/novoHealth/search',
  },
];

function Home() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>{race?.name ? `${race?.name} | Summary Page` : 'Loading...'}</title>
      </Helmet>
      <div className="relative">
        <div
          className="home absolute w-full h-48 bg-center bg-contain bg-no-repeat"
          style={{
            backgroundImage: 'url(/assets/images/novoHealth-cover.jpg)',
          }}>
          <div className="w-full h-52 absolute opacity-50 bg-black" style={{ zIndex: 1 }} />
        </div>
        <div className="sticky" style={{ zIndex: 2 }}>
          <div className="novoHealth-banner bg-transparent h-48">
            <p className="title">{race?.name}</p>
            <p className="subTitle">Summary Page</p>
          </div>
        </div>
      </div>
      <div className="container max-w-4xl mx-auto py-4 px-6 md:px-8 mt-4">
        <div className="grid grid-cols-12 gap-x-6 gap-y-4 mt-8 mb-6">
          {options.map(option => (
            <div
              key={option.id}
              className="col-span-6 sm:col-span-3 xl:col-span-3 flex flex-col duration-1000 transition-all cursor-pointer rounded-xl"
              role="presentation"
              onClick={() => navigate(option.link)}>
              <div className="bg-white flex flex-col h-full shadow-lg rounded-xl relative border border-gray-300 mb-4">
                <div className="h-full">
                  <div className="flex items-center justify-center py-4">{option.icon}</div>
                </div>
                <div className="text-center pb-4 px-3">
                  <h3 className="text-base font-semibold leading-normal">{option.name}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Home;
