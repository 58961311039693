/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { DatePicker } from 'react-nice-dates';
import { addDays, endOfDay, format, subDays } from 'date-fns';
import { enUS } from 'date-fns/locale';

import Spinner from '../../../components/loader/spinner';

import { fetchResultsDashboardNovo } from '../../../services/apis/business/result';

const race = {
  id: '350ee1c3-9e43-4273-900a-93ee51c63fec',
  name: 'Novohealth 5K',
};

function Dashboard() {
  const [startDate, setStartDate] = useState(new Date('2024-08-01'));
  const [endDate, setEndDate] = useState(new Date());

  const [fastest, setFastest] = useState([]);
  const [consistent, setConsistent] = useState([]);
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(true);

  const novoDashboard = useCallback(async (start, end) => {
    try {
      const response = await fetchResultsDashboardNovo(race.id, start, end);

      const { fastest: f, consistent: c, stats: s } = response;

      setFastest(f);
      setConsistent(c);
      setStats(s);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }

    (async () => {
      setLoading(true);

      await novoDashboard(
        format(startDate, 'dd/MM/yyyy'),
        format(endDate || endOfDay(new Date()), 'dd/MM/yyyy'),
      );
    })();
  }, [startDate, endDate]);

  return (
    <>
      <Helmet>
        <title>{race?.name ? `${race?.name} | Dashboard` : 'Loading...'}</title>
      </Helmet>
      <div className="relative">
        <div
          className="home absolute w-full h-48 bg-center bg-contain bg-no-repeat"
          style={{
            backgroundImage: 'url(/assets/images/novoHealth-cover.jpg)',
          }}>
          <div className="w-full h-52 absolute opacity-50 bg-black" style={{ zIndex: 1 }} />
        </div>
        <div className="sticky" style={{ zIndex: 2 }}>
          <div className="novoHealth-banner bg-transparent h-48">
            <p className="title">{race?.name}</p>
            <p className="subTitle">Dashboard</p>
          </div>
        </div>
      </div>
      <div className="container max-w-4xl mx-auto px-6 md:px-8 mt-4">
        <div className="flex flex-col py-4 items-start justify-center">
          <h1 className="text-base font-bold mb-2">Date Range</h1>
          <div className="flex flex-col lg:flex-row items-center justify-center">
            <DatePicker
              date={startDate}
              onDateChange={setStartDate}
              minimumDate={new Date('2024-08-01')}
              maximumDate={subDays(endDate, 1)}
              format="dd MMM yyyy"
              locale={enUS}>
              {({ inputProps, focused }) => (
                <>
                  <label htmlFor="startDate" className="text-sm font-bold text-gray-800">
                    Start
                  </label>
                  <div className="flex border text-base rounded mt-1 mb-1 border-gray-300">
                    <input
                      name="startDate"
                      className={`w-full px-2 py-1.5 placeholder-gray-600 text-sm input${
                        focused ? ' -focused' : ''
                      }`}
                      style={{ borderRadius: '3px' }}
                      {...inputProps}
                    />
                  </div>
                </>
              )}
            </DatePicker>
            <div className="w-4" />
            <DatePicker
              date={endDate}
              onDateChange={setEndDate}
              minimumDate={addDays(startDate, 1)}
              maximumDate={new Date()}
              format="dd MMM yyyy"
              locale={enUS}>
              {({ inputProps, focused }) => (
                <>
                  <label htmlFor="endDate" className="text-sm font-bold text-gray-800">
                    End
                  </label>
                  <div className="flex border text-base rounded mt-1 mb-1 border-gray-300">
                    <input
                      name="endDate"
                      className={`w-full px-2 py-1.5 placeholder-gray-600 text-sm input${
                        focused ? ' -focused' : ''
                      }`}
                      style={{ borderRadius: '3px' }}
                      {...inputProps}
                    />
                  </div>
                </>
              )}
            </DatePicker>
          </div>
        </div>
      </div>
      {loading && (
        <div className="container max-w-5xl mx-auto pb-6 px-6">
          <div className="mt-8">
            <Spinner size="medium" />
          </div>
        </div>
      )}
      {!loading && (
        <>
          <div className="container max-w-4xl mx-auto pt-4 px-6 md:px-8">
            <h1 className="text-lg font-bold">Fastest</h1>
            <div className="grid grid-cols-12 gap-x-6 gap-y-4 mt-4 mb-2">
              {fastest.length > 0 ? (
                fastest.map(item => (
                  <div
                    key={item.raceID}
                    className="col-span-12 sm:col-span-6 xl:col-span-6 flex flex-col duration-1000 transition-all cursor-pointer rounded-xl"
                    role="presentation"
                    onClick={() => null}>
                    <div className="bg-white flex flex-col h-full shadow-lg rounded-xl relative border border-gray-300 mb-4">
                      <div className="text-center py-4 px-3">
                        <h3 className="text-base font-semibold leading-normal">{item.label}</h3>
                        <br />
                        <h3 className="text-sm leading-normal">
                          Race: <strong>{item.raceName}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          BIB: <strong>{item.bibNo}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Name: <strong>{item.name}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Gender: <strong>{item.gender}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Time: <strong>{item.netTime}</strong>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-12">
                  <p className="mb-1 text-md">
                    <strong>No Results Found</strong>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="container max-w-4xl mx-auto pt-4 px-6 md:px-8">
            <h1 className="text-lg font-bold">Most Consistent</h1>
            <div className="grid grid-cols-12 gap-x-6 gap-y-4 mt-4 mb-2">
              {consistent.length > 0 ? (
                consistent.map(item => (
                  <div
                    key={item.raceID}
                    className="col-span-12 sm:col-span-6 xl:col-span-6 flex flex-col duration-1000 transition-all cursor-pointer rounded-xl"
                    role="presentation"
                    onClick={() => null}>
                    <div className="bg-white flex flex-col h-full shadow-lg rounded-xl relative border border-gray-300 mb-4">
                      <div className="text-center py-4 px-3">
                        <h3 className="text-base font-semibold leading-normal">{item.label}</h3>
                        <br />
                        <h3 className="text-sm leading-normal">
                          BIB: <strong>{item.bibNo}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Name: <strong>{item.name}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Gender: <strong>{item.gender}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Avg Time: <strong>{item.averageNetTime}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Races Ran: <strong>{item.raceCount}</strong>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-12">
                  <p className="mb-1 text-md">
                    <strong>No Results Found</strong>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="container max-w-4xl mx-auto pt-4 px-6 md:px-8">
            <h1 className="text-lg font-bold">Stats</h1>
            <div className="grid grid-cols-12 gap-x-6 gap-y-4 mt-4 mb-2">
              {stats.length > 0 ? (
                stats.map(item => (
                  <div
                    key={item.raceID}
                    className="col-span-12 sm:col-span-4 xl:col-span-4 flex flex-col duration-1000 transition-all cursor-pointer rounded-xl"
                    role="presentation"
                    onClick={() => null}>
                    <div className="bg-white flex flex-col h-full shadow-lg rounded-xl relative border border-gray-300 mb-4">
                      <div className="text-center py-4 px-3">
                        <h3 className="text-base font-semibold leading-normal">{item.raceName}</h3>
                        <br />
                        <h3 className="text-sm leading-normal">
                          Male Runners: <strong>{item.male}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Female Runners: <strong>{item.female}</strong>
                        </h3>
                        <h3 className="text-sm leading-normal">
                          Total Runners: <strong>{item.total}</strong>
                        </h3>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-span-12">
                  <p className="mb-1 text-md">
                    <strong>No Results Found</strong>
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
